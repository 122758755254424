<template>
  <el-container>
    <el-main>
      <el-row type="flex">
        <el-col id="rh-notfound">
          <p
            style="
              color: #409eff;
              font-weight: bold;
              font-size: 48px;
              margin-bottom: 0;
            "
          >
            4Oh4
          </p>
          <p style="font-size: 24px; margin-top: 0">
            Cette page n'existe pas !<br />Vérifiez l'url !
          </p>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
